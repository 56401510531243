import React from 'react';

interface Props {
	id: number;
	content: string;
	onClick: (id: number) => void;
}

export const Card: React.FC<Props> = ({ id, content, onClick }) => {
	return (
		<div className='Card'>
			<p className='Card__p'>{content}</p>
			<button className='Card__button' onClick={() => onClick(id)}>
				✔
			</button>
		</div>
	);
};
