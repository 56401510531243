// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding: 0;
  margin: 0;
}

.App {
  display:flex;
  flex-direction: column;
}

.Header {
  background-color: #0f0f52;
  height: 50px;
  display: flex;
  color: #fff;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.Button {
  margin: 1rem;
  max-width: 160px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  padding: 10px 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background: grey;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background: #f4f4f4;
  max-width: 300px;
  margin: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.Card__p {
  margin: 0px;
}

.Card__button {
  border: none;
  background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,wCAAwC;EACxC,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,wCAAwC;EACxC,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":["body {\n  padding: 0;\n  margin: 0;\n}\n\n.App {\n  display:flex;\n  flex-direction: column;\n}\n\n.Header {\n  background-color: #0f0f52;\n  height: 50px;\n  display: flex;\n  color: #fff;\n  justify-content: flex-end;\n  align-items: center;\n  padding: 0 20px;\n}\n\n.Button {\n  margin: 1rem;\n  max-width: 160px;\n  font-size: 16px;\n  cursor: pointer;\n  border-radius: 50px;\n  border: none;\n  padding: 10px 16px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  background: grey;\n}\n\n.Container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.Card {\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  padding: 16px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  background: #f4f4f4;\n  max-width: 300px;\n  margin: 16px;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 2rem;\n}\n\n.Card__p {\n  margin: 0px;\n}\n\n.Card__button {\n  border: none;\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
