import React, { useEffect } from 'react';

// Components
import { Button, Card, Container, Header } from './components';

// Styles
import './App.css';

interface ICard {
	content: string;
}

const App = () => {
	const [cards, setCards] = React.useState<ICard[]>([]);

	const onButtonPrint = () => window.print();

	const onCloseApp = () => setCards([]);

	const onCardClick = (id: number) => {
		setCards((prevCards) => {
			return prevCards.filter((_, index) => index !== id);
		});
	};

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			const pressedKey = event.key;
			const message = `You pressed hardware button: ${pressedKey}`;

			setCards((prevCards) => {
				return [...prevCards, { content: message }];
			});
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	return (
		<div className='App'>
			<Header />
			<Button onClick={onButtonPrint}>Print Test Page</Button>
			<Container>
				{cards.map(({ content }, index) => (
					<Card
						key={index}
						id={index}
						content={content}
						onClick={onCardClick}
					/>
				))}
			</Container>
			<Button onClick={onCloseApp}>Close Application</Button>
		</div>
	);
};

export default App;
