import React from 'react';

export const Header = () => {
	return (
		<header className='Header'>
			{/* This will be replaced by a logo */}
			<h4 className='Header__title'>Flex Ways</h4>{' '}
		</header>
	);
};
