import React from 'react';

interface Props {
	onClick: () => void;
	children: React.ReactNode;
}

export const Button: React.FC<Props> = ({ children, onClick }) => {
	return (
		<button className='Button' onClick={onClick}>
			{children}
		</button>
	);
};
